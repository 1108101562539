import React from "react";
import styles from "./book-flight-status.module.scss";
import FlightStatus from "../../component/booking-widget/flight-status";

const BookFlightStatus: React.FC = () => {
  return (
    <div className={styles["book-flight-status-container"]}>
      <div id="background-image"></div>
      <div className="global-content-padding">
        <div>
          <div>
            <FlightStatus />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookFlightStatus;
