import React from "react";
import FlightSchedule from "../../component/booking-widget/flight-schedule";
import styles from "./plan-flight-schedule.module.scss";
import { t } from "i18next";

const BookFlightSchedule: React.FC = () => {
  return (
      <div className={styles["book-flight-schedule-container"]}>
        <div id="background-image">
          <div className={styles["background-img-text"]}><div>{t("label_flightSchedule")}</div></div>
        </div>
        <div className="global-content-padding">
          <div>
            <div>
              <FlightSchedule />
            </div>
          </div>
        </div>
      </div>
  );
};

export default BookFlightSchedule;
