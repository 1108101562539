import React from "react";
import styles from "./e-Receipt-Page.module.scss";
import EReceipt from "../../component/booking-widget/e-Receipt";
import { t } from "i18next";

const EReceiptPage: React.FC = () => {
  return (
    <div className={styles["e-Receipt-container"]}>
      <div id="background-image" className="global-content-padding">
      <div className={styles["background-img-text"]}><div>{t("label_eReceipt")}</div></div>
      </div>
      <div className="global-content-padding">
        <div>
          <div className={styles["shadow"]}>
            <EReceipt />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EReceiptPage;
