import React, { useState, useEffect, useLayoutEffect } from "react";
import BookingWidget from "../../component/booking-widget/index";
import TGCarousel from "../../../../shared/tg-carousel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slice/RootReducer";
import { getPromotionDataRequest } from "../../slice/promotionSlice";
import { carouselData as carouselDataMock } from "./../../../../shared/storybook/index";
import styles from "./homepage.module.scss";
import { getROPDataRequest } from "../../slice/ropSlice";
import { getROHDataRequest } from "../../slice/rohSlice";
import { thaiCardDataRequest } from "../../slice/thaiCardApplicationSlice";
import { advertisementDataRequest } from "../../slice/advertisementSlice";
import { getPrivilegeDataRequest } from "../../slice/privilegeSlice";
import { languageDataRequest } from "../../slice/languageSlice";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import ROPMemberJoin from "../../component/rop-member-join";
import LifeStyle from "../../component/lifestyle";
import Promotion from "../../component/promotions";
import TGPlaceholder from "../../../../shared/tg-placeholder";
import NewsAnnouncements from "../../component/news-announcement";
import InspirationDeals from "../../component/inspiration-deals";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TGIcon from "../../../../shared/tg-icon";
import { useNavigate } from "react-router-dom";
import CheckInWidget from "../../pages/booking-widget-containers/check-in-widget";
import BookFlightWidget from "../booking-widget-containers/book-flight-widget";
import MoreServices from "../../component/booking-widget/more-services";

const Homepage = () => {
  const { t } = useTranslation();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFareDealAvailable, setIsFareDealAvailable] = useState(false);
  const { i18n } = useTranslation();

  const promotionRes = useSelector(
    (state: RootState) => state?.osciReducer?.promotionData.promotionDetails
  );
  const ropRes = useSelector(
    (state: RootState) => state?.osciReducer?.ropData.ropDetails
  );
  const isLoadingRopData = useSelector(
    (state: RootState) => state?.osciReducer?.ropData.isLoading
  );
  const rohRes = useSelector(
    (state: RootState) => state?.osciReducer?.rohData.rohDetails
  );
  const isLoadingRohData = useSelector(
    (state: RootState) => state?.osciReducer?.rohData.isLoading
  );
  const thaiCardData = useSelector(
    (state: RootState) => state?.osciReducer?.thaicard.ThaiCardDetails
  );

  const thaiCardLoadingData = useSelector(
    (state: RootState) => state?.osciReducer?.thaicard.isLoading
  );

  const privilegeRes = useSelector(
    (state: RootState) => state?.osciReducer?.privilegeData.privilegeDetails
  );
  const IsLoadingPrivilegeData = useSelector(
    (state: RootState) => state?.osciReducer?.privilegeData.isLoading
  );
  // const IsLoadingPromotionData = useSelector((state:RootState)=> state?.osciReducer?.promotionData.isLoading);

  const languageData = useSelector(
    (state: RootState) => state?.osciReducer?.languageSlice
  );

  const isDesktop = useScreenSize().device === "desktop";

  const [showToastTripCard, setShowToastTripCard] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const toast = useSelector((state: RootState) => state?.osciReducer?.deleteTripCard);
  const tripData = useSelector((state: RootState) => state?.osciReducer?.trips);
  //Trip Card Deletion Toast Message
  useEffect(() => {
    if (toast?.hasData) {
      setShowToastTripCard(true);
    }
    setTimeout(() => {
      setShowToastTripCard(false);
    }, 4000);
  }, [toast]);

  useEffect(() => {
    if (!toast?.isLoading && toast?.error?.status === 400) {
      setErrorToast(true);
    }
    setTimeout(() => {
      setErrorToast(false);
    }, 4000);
  }, [toast]);

  useEffect(() => {
    dispatch(
      getPromotionDataRequest({ currentLanguage: "en", currentCountry: "th" })
    );
    dispatch(getROPDataRequest());
    dispatch(
      getROHDataRequest({ currentLanguage: "en", currentCountry: "th" })
    );
    dispatch(thaiCardDataRequest());
    dispatch(advertisementDataRequest());
    dispatch(
      getPrivilegeDataRequest({ currentLanguage: "en", currentCountry: "th" })
    );

    dispatch(languageDataRequest());
  }, [dispatch]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 2000);
    handleCheck();
  });

  useLayoutEffect(() => {
    console.log("everymundo loading");
    var script = document.createElement("script");
    script.src = "https://em-frontend-assets.airtrfx.com/mm/x-start-dev.js";
    document.body.appendChild(script);
  }, []);

  const [isLanguageData, setIsLanguageData] = useState<any[]>([]);
  const userSelectLanguage = i18n.language?.split("-")?.[1]?.toLowerCase();
  function handleCheck() {
    languageData.countryList.map((val, index) => {
      setIsLanguageData(val.countryList);
    });
    console.log("languages data :", isLanguageData);
    console.log("user select language :", userSelectLanguage);
    if (isLanguageData.includes(userSelectLanguage)) {
      setIsFareDealAvailable(false);
    } else {
      setIsFareDealAvailable(true);
    }
  }

  const promotionData = promotionRes.map((x: any) => {
    return {
      ...x,
      mainHeading: x.title,
      subHeading: x.subTitle,
      hasButton: x.buttonUrl ? true : false,
      imageURl: x.image,
      buttonUrl: x.buttonUrl,
    };
  });

  const ropData = ropRes.map((x: any) => {
    return {
      ...x,
      mainHeading: x.title,
      subHeading: x.desc,
      hasButton: x.redirectionUrl ? true : false,
      buttonText: x.buttonText,
      imageURl: x.image,
      buttonUrl: x.redirectionUrl,
    };
  });

  const privilegeData = privilegeRes.map((x: any) => {
    return {
      ...x,
      mainHeading: x.title,
      subHeading: x.desc,
      hasButton: x.redirectionUrl ? true : false,
      buttonText: x.buttonText,
      imageURl: x.image,
      buttonUrl: x.redirectionUrl,
    };
  });

  const rohData = rohRes.map((x: any) => {
    return {
      ...x,
      mainHeading: x.title,
      subHeading: x.desc,
      hasButton: x.redirectionUrl ? true : false,
      imageURl: x.image,
      buttonUrl: x.redirectionUrl,
    };
  });

  const offersTab = [
    {
      tabName: "Special Offers",
      data: carouselDataMock,
      isLoading: true,
    },
    {
      tabName: "Privilege",
      data: privilegeData,
      isLoading: IsLoadingPrivilegeData,
    },
    {
      tabName: "Royal Orchid Plus",
      data: ropData,
      isLoading: isLoadingRopData,
    },
    {
      tabName: "Royal Orchid Holidays",
      data: rohData,
      isLoading: isLoadingRohData,
    },
  ];
  const handleClick = () => {
    window.location.href = `/${i18n.language}/myprofile`;
  };
  //mobile view carousel section
  useEffect(() => {
    let ele = document.getElementsByClassName(
      "next-div"
    ) as HTMLCollectionOf<HTMLElement>;
    if (
      typeof ele[index] !== "undefined" &&
      index < offersTab.length &&
      Number(index) !== 3
    ) {
      ele[index].style.backgroundImage = `url(${
        offersTab?.[Number(index) + 1]?.data?.[0]?.image
      })`;
    } else if (typeof ele[index] !== "undefined" && Number(index) === 3) {
      ele[
        index
      ].style.backgroundImage = `url(${offersTab?.[2]?.data?.[0]?.image})`;
    }
  }, [index, offersTab, document.querySelector(".next-div")]);

  // for animated header
  const [isPosition, setIsPosition] = useState();
  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    console.log("scrollTop, ", e.target);
  };

  return (
    <div
      className={styles["container-wrapper"]}
      onScroll={handleScroll}
      aria-label="Homepage"
    >
      {/* //delete trip card toast */}
      {isDesktopView &&
        !toast?.isLoading &&
        toast?.hasData &&
        showToastTripCard && (
          <div className={styles.toast} aria-label="delete trip card">
            <TGIcon icon="toast-icon" fillColor="" size="" />
            {t("delete_trip_card_toast")}
          </div>
        )}
      {isDesktopView &&
        toast?.tripCardStatus?.status === false &&
        errorToast && (
          <div className={styles.toast} aria-label="something went wrong">
            <TGIcon icon="failure-toast-icon" fillColor="" size="" />
            {t("label_something_went_wrong")}
          </div>
        )}
      {isLoaded ? (
        <TGCarousel
          customClassName="carouselFontFamily"
          variant="primary"
          carouselData={promotionData}
        />
      ) : (
        <TGPlaceholder
          typeClass="img"
          styleClass={styles.placeholder}
          sizeClass={12}
          variant="HTMLElement"
          animationVariant="wave"
        />
      )}
      <BookingWidget />
      {/* <CheckInWidget /> */}
      {/* <BookFlightWidget /> */}
      {isDesktopView && <MoreServices />}
      <div className={styles.positionde}>{isPosition}</div>

      <div
        className={`global-content-margin everymundo-container ${styles["everymundo-container"]}`}
      >
        {isFareDealAvailable ? (
          <div
            data-container-id={process.env.REACT_APP_POPULAR_FARE_DEAL_ID}
          ></div>
        ) : (
          // <ToDoList title={"Popular Fare Deals"} description={"To be integrated with Everymundo sdk."} />
          <InspirationDeals />
        )}
      </div>

      <ROPMemberJoin />
      <Promotion />
      <LifeStyle />
      <NewsAnnouncements />
    </div>
  );
};

export default Homepage;
